import { IsArray, IsNotEmpty, IsOptional, IsString, IsUUID, ValidateIf } from 'class-validator'
import { StringTrim } from '../decorators'
import { LabelValueDTO, MediaDTO, VistaDTO } from '../shared'
import { FspMatchEventDTO } from './match-events.dto'

export class MatchAnnouncementDocumentCreateDTO {
  order: number
  mediaId: string
  matchInformationId?: string
}

export class MatchAnnouncementCreateDTO {
  documents?: MatchAnnouncementDocumentCreateDTO[]
  text: string
  sendNotificationToMobileApp: boolean
}

export class MatchListDTO extends VistaDTO {
  dateMatch: Date
  dateMatchStd: Date
  dateMatchUtc: Date
  lastModified: Date
  matchType: number
  homeTeamId: string
  awayTeamId: string
  venueId: string
  siteId: string
  homeTeamImageUrl: string
  awayTeamImageUrl: string
  subEventIntegrationId: string
  roundId: string
  matchDayId: string
  matchDayName: string
  expectedAttendance: number
  lineupStatus: LineupStatus
  mapLink?: string
  venue: string
  country: string
  site: string
  homeTeam: string
  awayTeam: string
  matchDayNumber: number
  isSubscribed: boolean
  score?: MatchScoreDTO
  homeTeamCode: string
  awayTeamCode: string
  numberOfContacts: number
  numberOfActivitiesInTimeline: number
  numberOfDocuments: number
  numberOfAnnouncements: number
  timezone: string
  siteAddress: string
  siteCapacity: number
  siteImageUrl: string
  playerOfTheMatchId: string
}

export class UpdatedMatchesDTO {
  hasUpdates: boolean
}

export class MatchDTO extends MatchListDTO {
  contacts: MatchContactDTO[]
  timeline: MatchActivityDTO[]
  widgetTimeline: MatchActivityDTO[]
  announcements: MatchAnnouncementDTO[]
  documents: MatchDocumentDTO[]
  personEvents: MatchPersonEventDTO[]
  ifbPaths: LabelValueDTO[]
  lineup: MatchLineupDTO
  referees: MatchRefereeDTO[]
  eventsTimeline: MatchTimelineEventDTO[]
}

export class MatchCreateDTO {
  @StringTrim()
  @IsNotEmpty()
  integrationId?: string

  dateMatch: Date
  dateMatchStd: Date
  dateMatchUtc: Date

  matchType: number

  @ValidateIf(match => !match.homeTeamId)
  @IsNotEmpty()
  @IsString()
  homeTeamIntegrationId?: string

  @ValidateIf(match => !match.homeTeamIntegrationId)
  @IsUUID()
  @IsNotEmpty()
  homeTeamId?: string

  @ValidateIf(match => !match.awayTeamId)
  @IsNotEmpty()
  @IsString()
  awayTeamIntegrationId?: string

  @ValidateIf(match => !match.awayTeamIntegrationId)
  @IsUUID()
  @IsNotEmpty()
  awayTeamId?: string

  @IsString()
  @IsOptional()
  venueIntegrationId?: string

  @IsUUID()
  @IsOptional()
  venueId?: string

  @IsString()
  @IsOptional()
  siteIntegrationId?: string

  @IsUUID()
  @IsOptional()
  siteId?: string

  @IsString()
  @IsOptional()
  matchDayId: string

  @IsString()
  @IsOptional()
  matchDayName: string

  @IsString()
  @IsOptional()
  sessionId: string

  @IsOptional()
  @IsString()
  seasonId: string

  @IsOptional()
  lineup?: MatchLineupDTO

  @IsOptional()
  referees?: FspMatchRefereeDTO[]

  @IsOptional()
  expectedAttendance?: number

  @IsOptional()
  ifbPaths?: LabelValueDTO[]

  @IsOptional()
  lineupStatus?: LineupStatus

  @IsOptional()
  score: MatchScoreDTO

  @IsOptional()
  activities?: MatchActivityDTO[]

  @IsOptional()
  bbsMpsActivities?: MatchActivityDTO[]

  @IsOptional()
  contacts?: MatchContactDTO[]

  @IsOptional()
  documents?: MatchDocumentDTO[]

  @ValidateIf(match => !match.roundId)
  @IsNotEmpty()
  @IsString()
  roundIntegrationId?: string

  @ValidateIf(match => !match.roundIntegrationId)
  @IsUUID()
  @IsNotEmpty()
  roundId?: string

  @IsOptional()
  matchDayNum?: number

  @IsOptional()
  statusName?: string

  @IsOptional()
  lastModified?: Date

  @IsOptional()
  relatedMatchIntegrationId?: number

  @IsOptional()
  relatedMatchScore?: MatchScoreValue

  @IsOptional()
  stadiumCapacity?: number

  @IsOptional()
  stadiumImageUrl?: string

  @IsOptional()
  playerOfTheMatch?: string

  @IsOptional()
  @IsArray()
  matchEvents?: FspMatchEventDTO[]
}

export class MatchUpdateDTO extends MatchCreateDTO {}

export interface MatchLineupPlayerDTO {
  age: string
  birthDate: string
  clubJerseyNumber: string
  clubShirtName: string
  countryCode: string
  fieldPosition: 'GOALKEEPER' | 'DEFENDER' | 'MIDFIELDER' | 'FORWARD'
  height: number
  id: string
  imageUrl: string
  internationalName: string
  languages: string[]
}

export interface MatchLineupDTO {
  fieldCoordinate: { x: number; y: number }
  fspFieldCoordinate: { x: number; y: number }
  isBooked: boolean
  jerseyNumber: string
  type: 'GOALKEEPER' | 'CAPTAIN' | 'PLAYER'
  player: MatchLineupPlayerDTO
}

export class MatchPersonTranslationsDTO {
  name: { EN: string }
  shortName: { EN: string }
  countryName: { EN: string }
  firstName: { EN: string }
  lastName: { EN: string }
}

export class MatchPersonDTO {
  countryCode: string
  gender: string
  id: number
  translations: MatchPersonTranslationsDTO
  languages: string[]
}

export class MatchCoachDTO {
  imageUrl: string
  person: MatchPersonDTO
  role: string
}

export class MatchRefereeDTO {
  name: string
  imageUrl: string
  role: string
  country: string
}

export class FspMatchRefereeDTO {
  images: { SMALL_SQUARE: string }
  role: FspMatchRefereeRole
  translations: { roleName: { EN: string } }
  person: MatchPersonDTO
}

export interface MatchLineupTeamDTO {
  field: Array<MatchLineupDTO>
  bench: Array<MatchLineupDTO>
  coaches: Array<MatchCoachDTO>
  kitImageUrl: string
  shirtColor: string
}

export interface MatchLineupDTO {
  homeTeam: MatchLineupTeamDTO
  awayTeam: MatchLineupTeamDTO
}

export interface MatchScoreValue {
  home: number
  away: number
}

export interface MatchScoreDTO {
  aggregate: MatchScoreValue
  penalty: MatchScoreValue
  regular: MatchScoreValue
  total: MatchScoreValue
}

export interface MatchContactDTO {
  role: string
  name: string
  email: string
  phoneNumber: string
  id: string
  pictureUrl: string
}

export enum LineupStatus {
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  AVAILABLE = 'AVAILABLE',
  TACTICAL_AVAILABLE = 'TACTICAL_AVAILABLE',
}

const WidgetMatchActivityTeam = {
  Home: 'home',
  Away: 'away',
} as const
export type WidgetActivityTeam = typeof WidgetMatchActivityTeam[keyof typeof WidgetMatchActivityTeam] | null

const WidgetMatchActivityType = {
  Tv: 'tv',
  PressConference: 'press-conference',
  Training: 'training',
  E2eTest: 'e2e-test',
} as const
export type WidgetActivityType = typeof WidgetMatchActivityType[keyof typeof WidgetMatchActivityType] | null

export const FspMatchRefereeRole = {
  Referee: 'REFEREE',
  AssistantRefereeOne: 'ASSISTANT_REFEREE_ONE',
  AssistantRefereeTwo: 'ASSISTANT_REFEREE_TWO',
  FourthOfficial: 'FOURTH_OFFICIAL',
  VideoAssistantReferee: 'VIDEO_ASSISTANT_REFEREE',
  AssistantVideoAssistantReferee: 'ASSISTANT_VIDEO_ASSISTANT_REFEREE',
  AssistantVideoAssistantRefereeOne: 'ASSISTANT_VIDEO_ASSISTANT_REFEREE_ONE',
  AssistantVideoAssistantRefereeTwo: 'ASSISTANT_VIDEO_ASSISTANT_REFEREE_TWO',
  RefereeObserver: 'REFEREE_OBSERVER',
  UefaDelegate: 'UEFA_DELEGATE',
} as const
export type FspMatchRefereeRole = typeof FspMatchRefereeRole[keyof typeof FspMatchRefereeRole] | null

export interface MatchDocumentDTO {
  name: string
  url: string
  type: 'mro' | 'tv_maps_plans' | 'other'
  id: string
}

export interface MatchActivityDTO {
  id: string
  date: Date
  description: string
  matchDayNumber: number
  notes?: string
}

export interface WidgetMatchActivityDTO extends MatchActivityDTO {
  team: WidgetActivityTeam
  type: WidgetActivityType
}

export class MatchAnnouncementDTO {
  id: string
  documents: MatchMediaDTO[]
  date: Date
  createdBy: string
  text: string
  canModify: boolean
}

export class MatchMediaDTO extends MediaDTO {
  url: string
  metadata?: { type: 'mro' | 'tv_maps_plans' | 'other' }
  downloadUrl: string
}

export interface MatchBulkSyncResultDTO {
  total: number
  created: number
  updated: number
  failedCreate: number
  failedUpdate: number
  skipped: number
}

export const MatchPersonEventType = {
  RedCard: 'red_card',
  YellowCard: 'yellow_card',
  YellowRedCard: 'yellow_red_card',
  SubstitutionIn: 'substitution_in',
  SubstitutionOut: 'substitution_out',
  Goal: 'goal',
  OwnGoal: 'own_goal',
} as const
export type MatchPersonEventType = typeof MatchPersonEventType[keyof typeof MatchPersonEventType] | null

export class MatchPersonEventDTO {
  date: Date
  type: MatchPersonEventType
  personId: string
  minute: number
}
export const MatchTimelineEventType = {
  RedCard: 'red_card',
  YellowCard: 'yellow_card',
  YellowRedCard: 'yellow_red_card',
  Substitution: 'substitution',
  Goal: 'goal',
  OwnGoal: 'own_goal',
  GoalPenalty: 'goal_penalty',
  GoalDisallowed: 'goal_disallowed',
  PenaltyMissed: 'penalty_missed',
  InjuryMinute: 'injury_minute',
  StartPhase: 'start_phase',
  EndPhase: 'end_phase',
} as const
export type MatchTimelineEventType = typeof MatchTimelineEventType[keyof typeof MatchTimelineEventType] | null

export const MatchTimelineEventPhase = {
  FirstHalf: 'first_half',
  SecondHalf: 'second_half',
  ExtraTimeFirstHalf: 'extra_time_first_half',
  ExtraTimeSecondHalf: 'extra_time_second_half',
  Penalties: 'penalties',
} as const
export type MatchTimelineEventPhase = typeof MatchTimelineEventPhase[keyof typeof MatchTimelineEventPhase] | null

export class MatchTimelineEventDTO {
  date: Date
  type: MatchTimelineEventType
  phase: MatchTimelineEventPhase
  minute: number
  primaryActor?: string
  secondaryActor?: string
  teamId?: string
  injuryMinute?: number
  injuryMinutesAdded?: number
}
