import { IsNotEmpty, IsOptional, IsString, IsUUID, ValidateIf } from 'class-validator'
import { StringCleanup, StringTrim } from '../decorators'
import { VistaDTO } from '../shared/vista.dto'

export class SubEventDTO extends VistaDTO {
  eventId?: string
  shortName?: string
  startDate?: Date
  endDate?: Date
  matchId?: string
}

export class SubEventListDTO extends SubEventDTO {
  constructor(subEvent?: SubEventDTO) {
    super()
    this.id = subEvent?.id ?? null
    this.name = subEvent?.name ?? null
    this.shortName = subEvent?.shortName ?? null
    this.startDate = subEvent?.startDate ?? null
    this.endDate = subEvent?.endDate ?? null
    this.integrationId = subEvent?.integrationId ?? null
  }
}

export class SubEventUpdateDTO {
  @StringTrim()
  @StringCleanup()
  @IsNotEmpty()
  name: string

  @StringTrim()
  @StringCleanup()
  @IsNotEmpty()
  shortName: string

  @StringTrim()
  @IsOptional()
  @IsString()
  integrationId?: string

  @ValidateIf(subEvent => !subEvent.eventId)
  @IsNotEmpty()
  @IsString()
  eventIntegrationId?: string

  @ValidateIf(subEvent => !subEvent.eventIntegrationId)
  @IsUUID()
  @IsNotEmpty()
  eventId?: string

  @IsString()
  @IsOptional()
  matchIntegrationId?: string

  @IsUUID()
  @IsOptional()
  matchId?: string

  @IsNotEmpty()
  startDate: Date

  @IsNotEmpty()
  endDate: Date
}

export class SubEventCreateDTO extends SubEventUpdateDTO {}
