export enum BadRequest {
  ONLY_ONE_SECTION_LEVEL_DEPTH = 'ONLY_ONE_SECTION_LEVEL_DEPTH',
  SECTION_HAS_QUESTIONS = 'SECTION_HAS_QUESTIONS',
  SITE_VISIT_MISSING_FROM_TO_HANDOVER_HANDBACK = 'SITE_VISIT_MISSING_FROM_TO_HANDOVER_HANDBACK',
  QUESTION_FIELDS_LIST_NO_VALUES = 'QUESTION_FIELDS_LIST_NO_VALUES',
  SITE_VISIT_ID_REQUIRED = 'SITE_VISIT_ID_REQUIRED',
  FILE_WAS_ALREADY_UPLOADED = 'FILE_WAS_ALREADY_UPLOADED',
  SECTION_NAME_ALREADY_EXISTS = 'SECTION_NAME_ALREADY_EXISTS',
  INVALID_VENUE_ID = 'INVALID_VENUE_ID',
  INVALID_EVENT_IDS = 'INVALID_EVENT_IDS',
  INVALID_COMPETITION_IDS = 'INVALID_COMPETITION_IDS',
  INVALID_SITE_ID = 'INVALID_SITE_ID',
  INVALID_SITE_VISIT_ID = 'INVALID_SITE_VISIT_ID',
  INVALID_SECTION_ID = 'INVALID_SECTION_ID',
  INVALID_PROJECT_ID = 'INVALID_PROJECT_ID',
  INVALID_KEY_ID = 'INVALID_KEY_ID',
  INVALID_WORKING_VISIT_QUESTION_ID = 'INVALID_WORKING_VISIT_QUESTION_ID',
  INVALID_WORKING_VISIT_QUESTION_FIELD_ID = 'INVALID_WORKING_VISIT_QUESTION_FIELD_ID',
  INVALID_WORKING_VISIT_ID = 'INVALID_WORKING_VISIT_ID',
  ACTION_POINTS_NOT_ALLOWED_FOR_SITE_VISIT = 'ACTION_POINTS_NOT_ALLOWED_FOR_SITE_VISIT',
  DAMAGE_REPORTS_NOT_ALLOWED_FOR_SITE_VISIT = 'DAMAGE_REPORTS_NOT_ALLOWED_FOR_SITE_VISIT',
  DAMAGE_DATE_REQUIRED_FOR_HOHB = 'DAMAGE_DATE_REQUIRED_FOR_HOHB',
  SIGNATURE_REQUIRED_FOR_HOHB = 'SIGNATURE_REQUIRED_FOR_HOHB',
  UEFA_COMMENT_REQUIRED_FOR_HOHB = 'UEFA_COMMENT_REQUIRED_FOR_HOHB',
  DAMAGE_CAUSED_BY_REQUIRED_FOR_HOHB = 'DAMAGE_CAUSED_BY_REQUIRED_FOR_HOHB',
  STADIUM_REPRESENTATIVE_SIGNATURE_REQUIRED_FOR_HOHB = 'STADIUM_REPRESENTATIVE_SIGNATURE_REQUIRED_FOR_HOHB',
  UEFA_REPRESENTATIVE_REQUIRED_FOR_HOHB = 'UEFA_REPRESENTATIVE_REQUIRED_FOR_HOHB',
  CHANGE_SITE_VISIT_NOT_ALLOWED = 'CHANGE_SITE_VISIT_NOT_ALLOWED',
  SITE_NOT_VALID_FOR_GIVEN_EVENT = 'SITE_NOT_VALID_FOR_GIVEN_EVENT',
  NO_SITES_FOR_GIVEN_EVENT = 'NO_SITES_FOR_GIVEN_EVENT',
  FIELD_TABLE_ROWS_MUST_HAVE_SAME_NUMBER_COLUMNS = 'FIELD_TABLE_ROWS_MUST_HAVE_SAME_NUMBER_COLUMNS',
  INVALID_WORKING_VISIT_USER_IDS = 'INVALID_WORKING_VISIT_USER_IDS',
  INVALID_FIELD_NUMERIC_ANSWER = 'INVALID_FIELD_NUMERIC_ANSWER',
  INVALID_FIELD_PLAIN_TEXT_ANSWER = 'INVALID_FIELD_PLAIN_TEXT_ANSWER',
  INVALID_FIELD_YES_NO_ANSWER = 'INVALID_FIELD_YES_NO_ANSWER',
  INVALID_FIELD_LIST_ANSWER = 'INVALID_FIELD_LIST_ANSWER',
  ONLY_ACTIVE_WORKING_VISIT_CAN_BE_SUBMITED = 'ONLY_ACTIVE_WORKING_VISIT_CAN_BE_SUBMITED',
  CAN_NOT_SUBMIT_TO_DO_WORKING_VISIT_QUESTION = 'CAN_NOT_SUBMIT_TO_DO_WORKING_VISIT_QUESTION',
  QUESTION_STATUS_MUST_BE_EQUAL_OR_GREATER_THAN_SUBMITTED = 'QUESTION_STATUS_MUST_BE_EQUAL_OR_GREATER_THAN_SUBMITTED',
  WORKING_VISITS_MUST_BELONG_SAME_SITE_VISIT = 'WORKING_VISITS_MUST_BELONG_SAME_SITE_VISIT',
  WORKING_VISIT_QUESTION_MUST_BELONG_TO_SELECTED_SECTION = 'WORKING_VISIT_QUESTION_MUST_BELONG_TO_SELECTED_SECTION',
  ACTION_POINT_NOT_VALID = 'ACTION_POINT_NOT_VALID',
  INVALID_FIELD_SIGNATURE_ANSWER = 'INVALID_FIELD_SIGNATURE_ANSWER',
  INVALID_FIELD_TABLE_ANSWER = 'INVALID_FIELD_TABLE_ANSWER',
  QUESTION_WITHOUT_RICH_CONTENT_MUST_HAVE_FIELDS = 'QUESTION_WITHOUT_RICH_CONTENT_MUST_HAVE_FIELDS',
  PARENT_FIELD_MUST_BE_YES_NO = 'PARENT_FIELD_MUST_BE_YES_NO',
  QUESTION_MUST_BELONG_TO_THE_SAME_COMPETITION_AS_SECTION = 'QUESTION_MUST_BELONG_TO_THE_SAME_COMPETITION_AS_SECTION',
  INVALID_SECTION_PARENT = 'INVALID_SECTION_PARENT',
  DISABLED_ONLY_ALLOWED_FOR_TODO_AND_IN_PROGRESS_QUESTIONS = 'DISABLED_ONLY_ALLOWED_FOR_TODO_AND_IN_PROGRESS_QUESTIONS',
  UNKNOWN_EXPORT_FORMAT = 'UNKNOWN_EXPORT_FORMAT',
  EVERY_COMPETITION_MUST_HAVE_AT_LEAST_ONE_EVENT_ASSOCIATED = 'EVERY_COMPETITION_MUST_HAVE_AT_LEAST_ONE_EVENT_ASSOCIATED',
  EVERY_EVENT_MUST_BELONG_TO_A_SELECTED_COMPETITION = 'EVERY_EVENT_MUST_BELONG_TO_A_SELECTED_COMPETITION',
  SECTION_HAS_QUESTIONS_FOR_COMPETITION_REMOVED = 'SECTION_HAS_QUESTIONS_FOR_COMPETITION_REMOVED',
  QUESTION_IDS_SHOULD_NOT_BE_EMPTY = 'QUESTION_IDS_SHOULD_NOT_BE_EMPTY',
  EVENT_IDS_SHOULD_NOT_BE_EMPTY = 'EVENT_IDS_SHOULD_NOT_BE_EMPTY',
  INVALID_EDITORS = 'INVALID_EDITORS',
  WORKING_VISIT_OR_EVENTS_REQUIRED = 'WORKING_VISIT_OR_EVENTS_REQUIRED',
  DEFAULT_EVENT_SHOULD_BELONG_TO_CYCLE = 'DEFAULT_EVENT_SHOULD_BELONG_TO_CYCLE',
  SITE_VISIT_TEAM_REQUIRED = 'SITE_VISIT_TEAM_REQUIRED',
  ACTION_POINT_COMMENT_SHOULD_NOT_BE_EMPTY = 'ACTION_POINT_COMMENT_SHOULD_NOT_BE_EMPTY',
  CAN_NOT_BULK_UPDATE_ACTION_POINTS = 'CAN_NOT_BULK_UPDATE_ACTION_POINTS',
  SITE_NOT_CONFIGURED_FOR_THE_SELECT_EVENTS = 'SITE_NOT_CONFIGURED_FOR_THE_SELECT_EVENTS',
  NO_EVENT_OR_EVENT_CYCLE_FOUND = 'NO_EVENT_OR_EVENT_CYCLE_FOUND',
}
