import { BooleanParse, LabelValueDTO } from '@uefa-shared/contracts'
import { ArrayParse, StringCleanup, StringTrim } from '@uefa-vista/contracts'
import { Type } from 'class-transformer'
import { ArrayMinSize, IsArray, IsBoolean, IsEnum, IsNotEmpty, IsOptional, IsUUID, ValidateIf, ValidateNested } from 'class-validator'
import moment from 'moment'
import { QuestionStatus } from '../question'
import { SiteVisitType, SVROrganization, WorkingVisitStatus } from '../shared/enums'
import { SiteVisitUserDTO, SiteVisitUserUpdateDTO } from '../site-visit/site-visit-user.dto'

export class WorkingVisitListDTO {
  id?: string
  start: Date
  end: Date
  code: string
  description: string
  status: WorkingVisitStatus
  siteVisit: LabelValueDTO
  type: SiteVisitType
}

export class WorkingVisitDTO {
  id?: string
  start: Date
  end: Date
  code: string
  description: string
  status: WorkingVisitStatus
  from: SVROrganization[]
  to: SVROrganization[]
  excludedSections?: string[]
  excludedQuestions?: string[]
  siteVisitId?: string
}

export class WorkingVisitExportSearchDTO {
  @IsNotEmpty()
  @IsArray()
  @ArrayParse()
  @ArrayMinSize(1)
  ids?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @IsUUID('all', { each: true })
  eventIds?: string[]

  @IsOptional()
  siteId?: string

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  includeActionPoints?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  includeEditors?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  includeGuidelines?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  includePrefill?: boolean

  @IsOptional()
  @IsBoolean()
  @BooleanParse()
  exportMedia?: boolean = false

  @IsOptional()
  @IsArray()
  @ArrayParse()
  audienceIds?: string[]

  @IsOptional()
  @IsArray()
  @ArrayParse()
  @IsEnum(QuestionStatus, { each: true })
  status?: QuestionStatus[]
}

export class WorkingVisitUpdateDTO {
  @IsOptional()
  id?: string

  @IsNotEmpty()
  start: Date

  @IsNotEmpty()
  end: Date

  @IsNotEmpty()
  @StringTrim()
  @StringCleanup()
  code: string

  @IsNotEmpty()
  @StringTrim()
  @StringCleanup()
  description: string

  status?: WorkingVisitStatus

  @IsOptional()
  @ArrayParse()
  @IsEnum(SVROrganization, { each: true })
  @ValidateIf((visit: WorkingVisitUpdateDTO) => visit.from?.length > 0)
  from?: SVROrganization[]

  @IsOptional()
  @ArrayParse()
  @IsEnum(SVROrganization, { each: true })
  @ValidateIf((visit: WorkingVisitUpdateDTO) => visit.to?.length > 0)
  to?: SVROrganization[]

  @IsOptional()
  @ArrayParse()
  excludedSections?: string[]

  @IsOptional()
  @ArrayParse()
  excludedQuestions?: string[]

  @IsOptional()
  @IsArray()
  @Type(() => SiteVisitUserUpdateDTO)
  @ValidateNested({ each: true })
  users?: SiteVisitUserUpdateDTO[]

  constructor(model?: WorkingVisitDTO, users?: SiteVisitUserDTO[]) {
    if (model) {
      this.id = model.id
      this.start = model.start
      this.end = model.end
      this.code = model.code
      this.description = model.description
      this.status = model.status
      this.from = model.from
      this.to = model.to
      this.excludedSections = model.excludedSections
      this.excludedQuestions = model.excludedQuestions

      const visitUsers = users?.filter(u => u.visitIds.includes(model.id)) ?? []
      this.users = visitUsers.map(u => new SiteVisitUserUpdateDTO(u))
    }
  }
}

export class WorkingVisitCreateDTO extends WorkingVisitUpdateDTO {
  @IsOptional()
  @IsUUID()
  siteVisitId?: string
}

export class StatusByProject {
  constructor(public projectId: string, public projectName: string, public status: WorkingVisitStatus) {}
}

export class WorkingVisitOverviewDTO {
  siteVisitId: string
  siteVisitName: string
  eventId?: string
  eventCycleId?: string
  defaultEventId?: string
  id: string
  name: string
  description: string
  status: WorkingVisitStatus
  lastUpdatedDate: Date
  summaryUrl: string
  statusByProject: StatusByProject[]
}

export class WorkingVisitUtils {
  public static getNewWorkingVisitUpdateDTO(startDate?: Date, code?: string, description?: string): WorkingVisitUpdateDTO {
    const dto = new WorkingVisitUpdateDTO()

    startDate = moment(startDate ?? new Date()).toDate()

    dto.id = `new_${Math.random() * 9999}`
    dto.start = startDate
    dto.end = moment(startDate)
      .add(1, 'M')
      .toDate() // 1 month
    dto.code = code ?? 'WV1'
    dto.description = description ?? 'Working Visit 1'
    dto.status = WorkingVisitStatus.TO_DO
    dto.from = []
    dto.to = []
    dto.excludedSections = []
    dto.excludedQuestions = []
    dto.users = []

    return dto
  }

  public static getNewWorkingVisitCreateDTO(startDate?: Date, code?: string, description?: string): WorkingVisitCreateDTO {
    return this.getNewWorkingVisitUpdateDTO(startDate, code, description)
  }

  public static statusColor(status: WorkingVisitStatus) {
    switch (status) {
      case WorkingVisitStatus.APPROVED:
        return '#E6F4E9'
      case WorkingVisitStatus.REJECTED:
        return '#FAE7E9'
      case WorkingVisitStatus.IN_PROGRESS:
        return '#FFF8E5'
      case WorkingVisitStatus.SUBMITTED:
        return '#E7F7FC'
      default:
        return ''
    }
  }

  public static statusLabel(status: WorkingVisitStatus): string {
    switch (status) {
      case WorkingVisitStatus.REJECTED:
        return 'Rejected'
      case WorkingVisitStatus.APPROVED:
        return 'Approved'
      case WorkingVisitStatus.TO_DO:
        return 'To Do'
      case WorkingVisitStatus.IN_PROGRESS:
        return 'In Progress'
      case WorkingVisitStatus.SUBMITTED:
        return 'Submitted'
      default:
        return ''
    }
  }

  public static isStatusAllowedForReject(status: WorkingVisitStatus) {
    return this.getAllowedStatusForReject().includes(status)
  }

  public static isStatusAllowedForApprove(status: WorkingVisitStatus) {
    return this.getAllowedStatusForApprove().includes(status)
  }

  public static isStatusAllowedForSubmit(status: WorkingVisitStatus) {
    return this.getAllowedStatusForSubmit().includes(status)
  }

  public static getAllowedStatusForReject() {
    return [WorkingVisitStatus.APPROVED, WorkingVisitStatus.REJECTED, WorkingVisitStatus.SUBMITTED]
  }

  public static getAllowedStatusForApprove() {
    return [WorkingVisitStatus.APPROVED, WorkingVisitStatus.REJECTED, WorkingVisitStatus.SUBMITTED]
  }

  public static getAllowedStatusForSubmit() {
    return [WorkingVisitStatus.IN_PROGRESS, WorkingVisitStatus.TO_DO]
  }
}
